export const CASE_DATA = [
    {
        superTitle: 'Case Study: Engage · 7 min read time',
        title: 'Creating New Zealand\'s first resource (and qualification) on how to engage with government',
        superImage: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1624514212/image_9_u70mv4.png',
        superImageAlt: 'Engage Logo',
        superImageHeight: '24',
        superImageClass: 'mb-3',
        spacing: 'pt-2 pt-sm-5 pb-4',
        background: 'bg-cream',
        breadcrumbs: [
            {
                name: 'Case Studies',
                destination: '/cases'
            },
            {
                name: 'Engage',
                destination: '/cases/engage',
                last: true
            }
        ],
        content: [
            '<em>How do you teach organisations on how to work with government when hardly any of the resources exist?</em>',
        ],
        contentImage: 'https://res.cloudinary.com/dmcrkqndq/image/upload/v1625114770/RANZ-iStock-1173276130_vwiyiy.jpg',
        contentImageAlt: 'NZ Beehive',
        contentImageClass: 'w-100 h-100'
    },
    {
        title: 'The problem',
        background: 'bg-white',
        spacing: 'pt-3 pb-2',
        listContent:[
            'Holly, the managing director for Engage teaches organisations how to interact with governments through workshops.',
            'A lot of what she teaches comes from her experience as a ministerial advisor and in-house legal counsel. There were no textbooks, online resources or teachers in this field.  ' ,
            'Holly wanted to create the first and only accreditation on how to engage with government in New Zealand. Holly did not want to write the course. She wanted to do what she loves which was helping organisations work with the government. '
        ],
        listItemClasses: 'mb-4'
    },
    {
        title: 'The challenge',
        background: 'bg-white',
        spacing: 'pt-0 pb-2',
        listContent:[
            'Create the first documented resource on how to engage with government',
            'The resource needed to be accurate and also simplify complex legal concept',
            'The resource needs to be action-oriented and have downloadable templates',
            'The resource needs to be able to be accessed online',
            'The ability for Holly to sell her training on her website'
        ],
        listItemClasses: 'mb-4'
    },
    {
        title: 'What we did',
        background: 'bg-white',
        spacing: 'pt-0 pb-2',
        listContent:[
            'We collaborated with experts in the space and used the latest legal literature to ensure that our content was legally and politically validated',
            'Went through a series of discovery workshops with Holly to ensure a co-creation process',
            'Talked to a number of organisations that wanted to constructively engage with government',
        ],
        listItemClasses: 'mb-4',
    },
    {
        title: 'The outcome',
        background: 'bg-white',
        spacing: 'pt-0 pb-4',
        listContent:[
            'Created New Zealand\'s first government relations course',
            'Built a practical online course for organisations to \'learn-while-they-do\' government relations',
            'Coming soon - a launch party in Parliament (equiv to the Senate in the US), with a number of politicians and government officials invited',
        ],
        listItemClasses: 'mb-4',
    },
    {
        background: 'bg-cream',
        spacing: 'py-4',
        title: 'How do you teach organisations on how to work with government when hardly any of the resources exist?',
        content: [
            'Holly is the managing director of Engage — a consultancy and training provider for government and public relations in New Zealand. When we first met Holly, she delivered her training via face-to-face workshops. But her passion was in consulting. She found that it was difficult to scale her workshops and her time, especially around the country. She did not have the time to write an online course herself but she knew that her knowledge was very specialised. In fact, only a handful of people in the whole of New Zealand even know how to engage with government in the way that government expect. ',
            '<p class="border-left border-primary pl-3 my-5"><em>"All of the information was in my head. Initially I was skeptical on how everything would turn out — especially with the deep legal background that was required in making such a course." <br/><br/> <strong>– Holly</strong></em></p>',
            'There was no easy way to build this course quickly. In the research phase, we spent a lot of our time with Holly and in her workshops. We also consulted with experts and cited legal sources to ensure that the learning was aligned to New Zealand\'s constitution. Advocacy and government relations is a highly technical and soft skill all at the same time. We believed that creating effective learning would need to center around case studies. We also looked at ways to make legal doctrines and ideas as accessible as possible through easy-to-understand stories so students wouldn\'t have to go through the same process that we did. ',
            'So how did we do this? We took an action-mapping approach. And we did this by reverse-engineering the outcomes and broke them down into atomic concepts. As we did so, we identified the key skills and competencies and created learning around them. We also ensured a lot of supporting resources so a learner never feel that they were on their own. We added links, videos and summaries of legal cases that would support a student to ensure that they had as much information to succeed. ',
            '<hr class="my-5"/>',
            'As we started to get a better understanding of how advocacy works, we tried to think of how this would make most sense to a student. We wanted to make sure that students understood not just how to perform the action of conversing with government, but to do so constructively. This meant ensuring that students understood the law, what politicians and government representatives can and can\'t do, and what would be considered illegal. This meant that the first half of the course was about how and why the New Zealand democracy exists and works; and the second half on how to purposefully engage with government. The nanodegree provides both context and action-oriented learning so everyone is on the same page.',
            '<hr class="my-5"/>',
            'In terms of the learning approach, we decided to go with a bite-sized question first approach. This is because we wanted to ensure that corrective feedback was given especially where things would be illegal or put a student or government official in an awkward situation. Every single question is also backed up with detailed explanations to ensure that there is a safe space to learn how advocacy works. Each lesson also takes less than ten minutes. We wanted to position the nano-degree (a proprietary credential) as something that was accessible. When it comes to law and legal studies, there is a strong perception that it requires immense concentration and a lot of time. We didn\'t want that to be the same for Holly\'s course.',
            '<hr class="my-5"/>',
            'Holly will be launching her course in late 2021. She will be launching it in Parliament with lots of politicians and government officials involved. Early testing with government officials suggest huge excitement for the nanodegree. ',
            '<p class="border-left border-primary pl-3 my-5"><em>"Not only will this help citizens to reach out to us more effectively, but it will also allow us to help them in a way that we know we can. - New Zealand politician" <br/><br/> <strong>– Holly</strong></em></p>',
        ],
        image:'https://res.cloudinary.com/dqpij5loy/image/upload/q_auto/v1611968005/Rio_tinto_river_CarolStoker_NASA_Ames_Research_Center_rtw0gp.jpg',
        imageAlt: 'Acid mine drainage can turn waterways orange to red',
        imageCaption: 'Rio Tinto in Spain — Acid mine drainage can turn waterways orange to red'
    }
]